<template>
    <Dialog :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '60vw' }"
        :header="cliente.name ? 'Métodos de Atendimento de ' + cliente.name : 'Carregando...'">
        <AppLoadingWrapper v-if="loadingDialog" />
        <Fieldset v-if="!loadingDialog" :legend="cliente.id ? 'Inclusão' : 'Carregando...'">
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <label for="procedimento">Tipo de Exame</label>
                    <Dropdown dataKey="id" id="id" v-model="tipoExame" :disabled="form.id" :options="tipoExames"
                        optionLabel="descricao" placeholder="Selecione..."
                        :class="{ 'p-invalid': submitted && !tipoExame }"></Dropdown>
                    <small class="p-error" v-if="submitted && !tipoExame">É
                        Obrigatório informar o tipo de exame.
                    </small>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="valorPagar">Quem Agenda</label>
                    <Dropdown id="quemAgenda" v-model="form.quemAgenda" :options="agendaAvisa" optionValue="value"
                        optionLabel="label" placeholder="Selecione..."
                        :class="{ 'p-invalid': submitted && !form.quemAgenda }" />
                    <small class="p-error" v-if="submitted && !form.quemAgenda">É
                        obrigatório informar quem agenda.
                    </small>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="valorCobrar">Quem Avisa</label>
                    <Dropdown id="quemAvisa" v-model="form.quemAvisa" :options="agendaAvisa" optionValue="value"
                        optionLabel="label" placeholder="Selecione..."
                        :class="{ 'p-invalid': submitted && !form.quemAvisa }" />
                    <small class="p-error" v-if="submitted && !form.quemAvisa">É
                        obrigatório informar quem avisa</small>
                </div>

                <div class="field col-12 md:col-2">
                    <Button icon="pi pi-check" class="p-button-text p-button-primary" v-tooltip="'Salvar'"
                        style="margin-top: 24px" @click="save" :loading="loadingSave" />
                    <Button v-if="form.id" icon="pi pi-times" class="p-button-text p-button-secondary"
                        v-tooltip="'Cancelar'" style="margin-top: 24px; margin-left: 5px" @click="cancel" />
                </div>
            </div>
        </Fieldset>
        <Fieldset v-if="!loadingDialog" legend="Métodos de atendimento dinâmico por exame" style="margin-top: 10px">
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <DataTable dataKey="id" :value="records" :row-hover="true" class="p-datatable-sm" :scrollable="true"
                scrollHeight="280px" responsiveLayout="stack" @sort="onSort">
                <template #empty>
                    Não há Métodos de Atendimentos configurados ainda!
                </template>
                <template #loading> Carregando registros. Aguarde ... </template>
                <Column bodyClass="text-left" style="flex: 0 0 4rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-text p-button-info" v-tooltip="'Editar'"
                            @click="onClickEditRecord(slotProps.data)" />
                    </template>
                </Column>
                <Column :sortable="true" field="tipoExame.descricao" header="Tipo do Exame"></Column>
                <Column :sortable="true" field="customer.name" header="Cliente"></Column>
                <Column :sortable="true" field="quemAgenda" header="Quem Agenda">
                    <template #body="slotProps">
                        {{ renderAgendaAvisa(slotProps.data?.quemAgenda) }}
                    </template>
                </Column>
                <Column :sortable="true" field="quemAvisa" header="Quem Avisa">
                    <template #body="slotProps">
                        {{ renderAgendaAvisa(slotProps.data?.quemAvisa) }}
                    </template>
                </Column>
                <Column bodyClass="text-right" style="flex: 0 0 4rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-text p-button-danger" v-tooltip="'Excluir'"
                            @click="confirmDeleteRecord(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </Fieldset>
        <AppDeleteDialog v-if="showAppDeleteDialog" v-model:visible="showAppDeleteDialog" :loadingRemove="loadingRemove"
            @onConfirm="onClickDeleteRecord" @onClose="onClickCloseDeleteRecord">
        </AppDeleteDialog>
        <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';
import BaseService from '../../../services/BaseService';
import AreaService from '../../../services/AreaMedicaService';
import QuemAgendaAvisa from '../../../enums/QuemAgendaAvisa';
import * as storage from '../../../common/storage';
import { limitarCaracteres } from '../../../services/auth';
import { getCurrentCompany } from '@/services/store';

export default {
    props: ['cliente'],
    data() {
        return {
            records: [],
            agendaAvisa: [],
            tipoExames: [],
            tipoExame: null,
            loading: false,
            submitted: false,
            loadingSave: false,
            loadingDialog: false,
            menuItems: [
                {
                    label: 'Deletar',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeleteRecord(this.record)
                }],
            form: {
                customer: this.cliente.id
            },
            showAppDeleteDialog: false,
            sort: null,
            nomeReduzidoEmpresa: null
        }
    },
    async created() {
        this.loadingDialog = true;
        this.$service = new BaseService('/cliente-metodo-atendimento');
        this.$serviceCompanies = new BaseService('/companies');
        const companyId = getCurrentCompany();
        const { data } = await this.$serviceCompanies.findById(companyId);
        this.nomeReduzidoEmpresa = limitarCaracteres(data.name, 38);
        this.load();
        this.carregarTipoExame();
        this.quemAgendaAvisa();
        this.profile = storage.getProfile();
        this.loadingDialog = false;
    },
    computed: {
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        tipoExame() {
            this.form.tipoExame = this.tipoExame.id;
        }
    },
    methods: {
        async save() {
            this.loadingSave = true;
            this.submitted = true;
            this.form.customer = this.cliente.id;
            try {
                await this.$service.save(this.form);
                this.$toast.add({ severity: 'success', summary: 'Método cadastrado com sucesso!', life: 3000 });
                this.load();
                this.form = {};
                this.submitted = false;
                this.tipoExame = {};
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao salvar método, ' + error.response.data.message, life: 3000 });
            }
            this.loadingSave = false;
        },
        async load() {
            this.loading = true;
            try {
                const path = `/cliente-metodo-atendimento/find-by-cliente/${this.cliente.id}`;
                const { data } = await getClientBase().get(path);
                this.records = data;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 5000 });
            }
            this.loading = false;
        },
        async carregarTipoExame() {
            this.$serviceTipoExame = new AreaService('/tipo_exames');
            const { data } = await this.$serviceTipoExame.findAll({});
            this.tipoExames = data;
        },
        async quemAgendaAvisa() {
            this.agendaAvisa = [
                { label: 'Solicitante', value: QuemAgendaAvisa.SOLICITANTE },
                { label: this.nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA },
                { label: 'Usuário Escolhe', value: QuemAgendaAvisa.USUARIO_ESCOLHE }
            ];
        },
        toggle($event, record) {
            this.record = record;
            this.$refs.menu.toggle($event);
        },
        async confirmDeleteRecord(record) {
            try {
                await this.$service.remove(record.id);
                this.load();
                this.deleteRecordDialog = false;
                this.$toast.add({ severity: 'success', summary: 'Método excluído com sucesso!', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o método!', life: 3000 });
            }
        },
        onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;
            const field = event.sortField?.split(/(?=[A-Z])/).join('_').toLowerCase() ? `${event.sortField?.split(/(?=[A-Z])/).join('_').toLowerCase()}` : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        },
        renderAgendaAvisa(tipo) {
            switch (tipo) {
                case QuemAgendaAvisa.SOLICITANTE:
                    return 'Solicitante';
                case QuemAgendaAvisa.EMPRESA:
                    return this.nomeReduzidoEmpresa;
                case QuemAgendaAvisa.USUARIO_ESCOLHE:
                    return 'Usuário Escolhe';
                default:
                    return 'Inconsistênte';
            }
        },
        onClickEditRecord(form) {
            this.form = JSON.parse(JSON.stringify(form));
            this.tipoExame = this.form.tipoExame;
        },
        cancel() {
            this.form = {};
            this.submitted = false;
        },
    },
}
</script>
<style lang="scss" scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #F4F4F4;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    background: #F4F4F4;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
}
</style>