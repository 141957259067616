<script setup>
import { ref, defineProps, defineExpose } from 'vue';
import { showError, showSuccess } from '@/utils/Toast';
import { useToast } from 'primevue/usetoast';
import { getClientBase } from '@/services/http';
import BaseService from '@/services/BaseService';
import { EnumTipoContatoLabel } from '@/enums/EnumTipoContato';
import { EnumClassificacaoContatoLabel } from '@/enums/EnumClassificacaoContato';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';

const props = defineProps({
    cliente: {
        type: Object,
        required: true
    }
});

const toast = useToast();
const service = new BaseService('/customer-contato');
const itensTipo = Object.entries(EnumTipoContatoLabel).map(([key, value]) => {
    return { label: value, value: key };
});
const itensClassificacao = Object.entries(EnumClassificacaoContatoLabel).map(([key, value]) => {
    return { label: value, value: key };
});

const contato = ref({});
const contatos = ref([]);
const loading = ref(false);
const visivel = ref(false);
const contatoExclusao = ref(null);
const dialogExcluirContato = ref(false);

const rules = {
    nome: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    email: {
        required: helpers.withMessage('Campo obrigatório', required),
        email: helpers.withMessage('E-mail inserido é inválido', email)
    },
    contato: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    cargo: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    tipoContato: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    classificacaoContato: {
        required: helpers.withMessage('Campo obrigatório', required)
    }
};
const v$ = useVuelidate(rules, contato);

async function abrir() {
    v$.value.$reset();
    await buscarContatos();
    visivel.value = true;
}
async function buscarContatos() {
    loading.value = true;
    try {
        const { data } = await getClientBase().get('/customer-contato', {
            params: {
                filtrosExtras: {
                    customerId: props.cliente.id
                }
            }
        });

        contatos.value = data.items;
    } catch (error) {
        const message = error.response.data.message;
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: message,
            life: 5000
        });
    }
    loading.value = false;
}
async function salvarContato() {
    loading.value = true;
    try {
        contato.value.customerId = props.cliente.id;
        await service.save(contato.value);

        buscarContatos();
        v$.value.$reset();
        contato.value = {};
        showSuccess(toast, 'Contato salvo com sucesso!');
    } catch (error) {
        const message = error?.response?.data?.details?.response?.message[0] || error.response.data.message;
        showError(toast, message);
    }
    loading.value = false;
}
async function excluirContato() {
    loading.value = true;
    try {
        await service.remove(contatoExclusao.value.id);

        showSuccess(toast, 'Contato excluído com sucesso!');
        dialogExcluirContato.value = false;
        contatoExclusao.value = {};
        buscarContatos();
    } catch (error) {
        const message = error?.response?.data?.details?.response?.message[0] || error.response.data.message;
        showError(toast, message);
    }
    loading.value = false;
}
async function validarFormulario() {
    const formularioCorreto = await v$.value.$validate();
    if (formularioCorreto) salvarContato();
}
function abrirDialogConfirmarExclusao(contato) {
    contatoExclusao.value = contato;
    dialogExcluirContato.value = true;
}
function editarContato(form) {
    contato.value = { ...form };
}
function fechar() {
    visivel.value = false;
    contato.value = {};
    contatos.value = [];
    loading.value = false;
    contatoExclusao.value = null;
    dialogExcluirContato.value = false;
}

defineExpose({ abrir });
</script>
<template>
    <Dialog
        v-model:visible="visivel"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :header="`Cliente: ${cliente.name}`"
        :style="{ width: '60vw' }"
    >
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="font-semibold text-lg mb-4">Incluir contato</div>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <label for="nome">Nome</label>
                    <InputText v-model="contato.nome" :class="{ 'p-invalid': v$.nome.$error }" id="nome" />
                    <small v-if="v$.nome.$error" class="p-error">
                        {{ v$.nome.$errors[0].$message }}
                    </small>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="email">Email</label>
                    <InputText v-model="contato.email" :class="{ 'p-invalid': v$.email.$error }" id="email" />
                    <small v-if="v$.email.$error" class="p-error">
                        {{ v$.email.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="contato">Telefone</label>
                    <InputMask v-model="contato.contato" :class="{ 'p-invalid': v$.contato.$error }" id="contato" mask="(99) 99999-9999" />
                    <small v-if="v$.contato.$error" class="p-error">
                        {{ v$.contato.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="cargo">Cargo</label>
                    <InputText v-model="contato.cargo" :class="{ 'p-invalid': v$.cargo.$error }" id="cargo" />
                    <small v-if="v$.cargo.$error" class="p-error">
                        {{ v$.cargo.$errors[0].$message }}
                    </small>
                </div>
                <div class="field col-12 md:col-4">
                    <label for="tipoContato">Responsável por</label>
                    <Dropdown
                        id="tipoContato"
                        v-model="contato.tipoContato"
                        :class="{ 'p-invalid': v$.tipoContato.$error }"
                        :options="itensTipo"
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Selecionar"
                    />
                    <small v-if="v$.tipoContato.$error" class="p-error">
                        {{ v$.tipoContato.$errors[0].$message }}
                    </small>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="classificacaoContato">Tipo</label>
                    <Dropdown
                        id="classificacaoContato"
                        v-model="contato.classificacaoContato"
                        :class="{ 'p-invalid': v$.classificacaoContato.$error }"
                        :options="itensClassificacao"
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Selecionar"
                    />
                    <small v-if="v$.classificacaoContato.$error" class="p-error">
                        {{ v$.classificacaoContato.$errors[0].$message }}
                    </small>
                </div>

                <div class="flex justify-content-end w-full px-3 pt-2">
                    <Button :loading="loading" label="Salvar contato" outlined style="width: max-content" @click="validarFormulario" />
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <div class="font-semibold text-lg mb-4">Contatos</div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <DataTable :value="contatos" class="p-datatable-sm" responsiveLayout="stack" row-hover scrollable scrollHeight="280px">
                <template #empty> Não há contatos cadastrados ainda! </template>
                <template #loading> Carregando registros. Aguarde ... </template>
                <Column bodyClass="text-left" style="flex: 0 0 4rem">
                    <template #body="slotProps">
                        <Button
                            icon="pi pi-pencil"
                            class="p-button-text p-button-info"
                            v-tooltip="'Editar contato'"
                            @click="editarContato(slotProps.data)"
                        />
                    </template>
                </Column>
                <Column :sortable="true" field="nome" header="Nome" />
                <Column :sortable="true" field="email" header="Email" />
                <Column :sortable="true" field="contato" header="Telefone" />
                <Column :sortable="true" field="cargo" header="Cargo" />
                <Column :sortable="true" field="tipoContato" header="Responsável por">
                    <template #body="slotProps">
                        {{ EnumTipoContatoLabel?.[slotProps.data?.tipoContato] }}
                    </template>
                </Column>
                <Column :sortable="true" field="classificacaoContato" header="Tipo">
                    <template #body="slotProps">
                        {{ EnumClassificacaoContatoLabel?.[slotProps.data?.classificacaoContato] }}
                    </template>
                </Column>
                <Column bodyClass="text-right" style="flex: 0 0 4rem">
                    <template #body="slotProps">
                        <Button
                            v-tooltip="'Excluir contato'"
                            class="p-button-text p-button-danger"
                            icon="pi pi-trash"
                            @click="abrirDialogConfirmarExclusao(slotProps.data)"
                        />
                    </template>
                </Column>
            </DataTable>
        </div>
        <AppDeleteDialog
            v-model:visible="dialogExcluirContato"
            :loadingRemove="loading"
            @onClose="dialogExcluirContato = false"
            @onConfirm="excluirContato()"
        />
        <template #footer>
            <Button :loading="loading" class="p-button-primary" label="Fechar" @click="fechar()" />
        </template>
    </Dialog>
</template>
