const EnumTipoContato = {
    CONVOCACAO_EXAME: 'CONVOCACAO_EXAME',
    CONTRATO: 'CONTRATO',
    OPERACAO: 'OPERACAO',
    ESOCIAL: 'ESOCIAL'
};

export const EnumTipoContatoLabel = {
    CONVOCACAO_EXAME: 'Convocação de exames',
    CONTRATO: 'Contrato',
    OPERACAO: 'Operação',
    ESOCIAL: 'eSocial'
};

export default EnumTipoContato;
