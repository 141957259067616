const EnumClassificacaoContato = {
    INTERNO: 'INTERNO',
    CLIENTE: 'CLIENTE'
};

export const EnumClassificacaoContatoLabel = {
    INTERNO: 'Interno',
    CLIENTE: 'Cliente'
};

export default EnumClassificacaoContato;
